var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        "label-cols-md": "3",
                        label: _vm.$t("MESSAGE_RETENTION"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "w-150",
                        attrs: {
                          trim: "",
                          type: "number",
                          name: "editMessageRetention",
                        },
                        model: {
                          value: _vm.settings.retention,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "retention", $$v)
                          },
                          expression: "settings.retention",
                        },
                      }),
                      _vm._v("\n      " + _vm._s(_vm.$t("DAYS")) + "\n      "),
                      _c("b-form-text", [_vm._v(_vm._s(_vm.$t("0_DISABLED")))]),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        "label-cols-md": "3",
                        label: _vm.$t("ACCOUNTS_CAN_REMOVE"),
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          switch: "",
                          value: true,
                          "unchecked-value": false,
                        },
                        model: {
                          value: _vm.settings.users_can_remove,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "users_can_remove", $$v)
                          },
                          expression: "settings.users_can_remove",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        "label-cols-md": "3",
                        label: _vm.$t("DELEGATE_ACCESS"),
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          switch: "",
                          value: true,
                          "unchecked-value": false,
                        },
                        model: {
                          value: _vm.settings.accounts_can_delegate,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "accounts_can_delegate", $$v)
                          },
                          expression: "settings.accounts_can_delegate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        "label-cols-md": "3",
                        label: _vm.$t("NOTIFICATIONS") + " " + _vm.$t("VIEWED"),
                      },
                    },
                    [
                      _c("b-form-checkbox", {
                        attrs: {
                          switch: "",
                          value: true,
                          "unchecked-value": false,
                        },
                        model: {
                          value: _vm.settings.email_notification_opened,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.settings,
                              "email_notification_opened",
                              $$v
                            )
                          },
                          expression: "settings.email_notification_opened",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "primary" },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
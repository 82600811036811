var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("div", { staticClass: "info-header mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("STATE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2 mt-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            switch: "",
                            value: "1",
                            "unchecked-value": "0",
                          },
                          model: {
                            value: _vm.settings.enabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "enabled", $$v)
                            },
                            expression: "settings.enabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("STATUS_ACTIVE")))]
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "primary" },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-form-group",
            { attrs: { "label-cols-md": "4", label: _vm.$t("NAME") } },
            [
              _c("b-form-input", {
                attrs: { trim: "", required: "", type: "text", name: "name" },
                model: {
                  value: _vm.information.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.information, "name", $$v)
                  },
                  expression: "information.name",
                },
              }),
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-md": "4", label: _vm.$t("STATUS_ACTIVE") } },
            [
              _c("b-form-checkbox", {
                staticClass: "mt-2",
                attrs: {
                  id: "checkbox-1",
                  name: "checkbox-1",
                  value: true,
                  switch: "",
                  "unchecked-value": false,
                },
                model: {
                  value: _vm.information.active,
                  callback: function ($$v) {
                    _vm.$set(_vm.information, "active", $$v)
                  },
                  expression: "information.active",
                },
              }),
            ],
            1
          ),
          _c("hr", { staticClass: "p-0 m-0 mt-2 mb-2" }),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill float-right",
              attrs: { variant: "danger" },
              on: { click: _vm.removeSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("REMOVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
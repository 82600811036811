var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        [
          _c(
            "b-nav",
            { staticClass: "hidden-mobile", attrs: { tabs: "" } },
            [
              _c(
                "b-nav-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
              ),
            ],
            1
          ),
          _c(
            "b-dropdown",
            {
              staticClass: "hidden-not-mobile",
              scopedSlots: _vm._u(
                [
                  {
                    key: "button-content",
                    fn: function () {
                      return [
                        _vm.tab == 0
                          ? _c("span", [_vm._v(_vm._s(_vm.$t("SETTINGS")))])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1006944245
              ),
            },
            [
              _c(
                "b-dropdown-item",
                {
                  attrs: { active: _vm.tab == 0 },
                  on: {
                    click: function ($event) {
                      _vm.tab = 0
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("SETTINGS")))]
              ),
            ],
            1
          ),
          _vm.tab == 0
            ? _c("Settings", { attrs: { organisation: _vm.organisation } })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="mt-2" v-if="organisation">
    <b-overlay :show="loading" rounded="sm" v-if="settings">

      
      <div class="info-header mt-2">{{ $t("STATE") }}</div>
      <div class="ml-2 mt-2">
        <b-form-checkbox
          v-model="settings.enabled"
          switch
          value="1"
          unchecked-value="0"
        >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>
      </div>

      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      settings: null,
      loading: false,
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .get(
          this.user.hostname +
            "/filestorage/settings/" +
            this.organisation.organisation_uuid
        )
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(
          this.user.hostname + "/filestorage/settings",
          self.settings
        )
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>
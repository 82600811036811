<template>
    <section v-if="organisation">
      
      <b-nav tabs class="hidden-mobile">
        <b-nav-item :active="tab == 0" @click="tab = 0">{{$t('SETTINGS')}}</b-nav-item>
      </b-nav>
      
      <b-dropdown class="hidden-not-mobile">
        <template #button-content>
          <span v-if="tab == 0">{{$t('SETTINGS') }}</span>
        </template>
        <b-dropdown-item :active="tab == 0" @click="tab = 0">{{$t('SETTINGS')}}</b-dropdown-item>
      </b-dropdown>

      <Settings v-if="tab == 0" :organisation="organisation"></Settings>
    </section>
  </template>
  <script>
  import Settings from '@/components/Organisation/FileStorage/Settings';
  export default {
    props: ["organisation"],
    components: {
      Settings
    },
    data() {
      return {
        tab: 0
      };
    },
    methods: {
    },
    watch: { 
    },
    computed: {
    },
    mounted() {
    }
  };
  </script>
  <style></style>
<template>
  <section class="mt-2" v-if="organisation">
    <b-overlay :show="loading" rounded="sm" v-if="settings">

      
      <div class="info-header mt-2">{{ $t("STATE") }}</div>
      <div class="ml-2 mt-2">
        <b-form-checkbox
          v-model="settings.enabled"
          :value="true"
          :unchecked-value="false"
          switch
        >{{ $t('STATUS_ACTIVE') }}</b-form-checkbox>
      </div>

      <div class="info-header mt-4">{{ $t("ENFORCE_SUBJECT") }}</div>
      <div class="ml-2 mt-2">

        <b-form-input trim v-model="settings.subject"></b-form-input>
        <b-form-checkbox class="mt-2"
          v-if="settings.subject"
          v-model="settings.unlock_subject"
          :value="true"
          :unchecked-value="false"
        >{{ $t('USER_CAN_UNLOCK') }}</b-form-checkbox>

      </div>
      
      <div class="info-header mt-4">{{ $t("ENFORCE_EMAIL_BODY") }}</div>
      <div class="ml-2 mt-2">

             <b-form-textarea v-model="settings.email_body"></b-form-textarea>

        <b-form-checkbox class="mt-2"
          v-if="settings.email_body"
          v-model="settings.unlock_email_body"
          :value="true"
          :unchecked-value="false"
        >{{ $t('USER_CAN_UNLOCK') }}</b-form-checkbox>
            
      </div>
      <div class="info-header mt-4">{{ $t("MISC") }}</div>
      <div class="ml-2 mt-2">

        <b-form-group label-cols-md="3" class="mb-2" :label="$t('BUTTON_TEXT')">
          <b-form-input trim v-model="settings.send_btn_text"></b-form-input>
        </b-form-group>

          <b-form-checkbox
          class="mt-2"
          switch
            v-model="settings.send_ics"
            :value="true"
            :unchecked-value="false"
          >{{ $t('SEND_ICS') }}</b-form-checkbox>

      </div>


      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      settings: null,
      loading: false,
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "JitsiSecure",
          organisation_uuid: this.organisation.organisation_uuid,
          functionbox_uuid: "",
        }
        )
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/save", self.settings)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>

<template>
  <section class="mt-2" v-if="organisation">
    <b-overlay :show="loading" rounded="sm" v-if="settings">
      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('MESSAGE_RETENTION')"
      >
        <b-form-input trim
          class="w-150"
          type="number"
          v-model="settings.retention"
          name="editMessageRetention"
        ></b-form-input>
        {{ $t("DAYS") }}
        <b-form-text>{{ $t("0_DISABLED") }}</b-form-text>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('ACCOUNTS_CAN_REMOVE')"
      >
        <b-form-checkbox
          v-model="settings.users_can_remove"
          switch
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
      </b-form-group>

      <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('DELEGATE_ACCESS')"
      >
        <b-form-checkbox
          v-model="settings.accounts_can_delegate"
          switch
          :value="true"
          :unchecked-value="false"
        ></b-form-checkbox>
      </b-form-group>

      <b-form-group
      label-cols-md="3"
      class="mb-2"
      :label="$t('NOTIFICATIONS') + ' ' + $t('VIEWED')"
    >
      <b-form-checkbox
        v-model="settings.email_notification_opened"
        switch
        :value="true"
        :unchecked-value="false"
      ></b-form-checkbox>
    </b-form-group>


      <hr class="p-0 m-0 mt-4 mb-2" />

      <b-button variant="primary" class="btn-fill" @click="updateSettings">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["organisation"],
  data() {
    return {
      settings: null,
      loading: false,
    };
  },
  methods: {
    getSettings() {
      let self = this;
      self.loading = true;
      this.$http
        .post(this.user.hostname + "/settings/get", {
          setting: "GeneralMessage",
          organisation_uuid: this.organisation.organisation_uuid,
          functionbox_uuid: "",
        }
        )
        .then(function (result) {
          self.settings = result.data;
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
    updateSettings() {
      let self = this;
      self.loading = true;
      this.settings.retention = parseInt(this.settings.retention);
      this.$http
        .post(this.user.hostname + "/settings/save", self.settings)
        .then(function () {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function () {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getSettings();
  },
};
</script>
<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.organisation
    ? _c(
        "section",
        { staticClass: "mt-2" },
        [
          _vm.settings
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading, rounded: "sm" } },
                [
                  _c("div", { staticClass: "info-header mt-2" }, [
                    _vm._v(_vm._s(_vm.$t("STATE"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2 mt-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            value: true,
                            "unchecked-value": false,
                            switch: "",
                          },
                          model: {
                            value: _vm.settings.enabled,
                            callback: function ($$v) {
                              _vm.$set(_vm.settings, "enabled", $$v)
                            },
                            expression: "settings.enabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("STATUS_ACTIVE")))]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info-header mt-4" }, [
                    _vm._v(_vm._s(_vm.$t("ENFORCE_SUBJECT"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2 mt-2" },
                    [
                      _c("b-form-input", {
                        attrs: { trim: "" },
                        model: {
                          value: _vm.settings.subject,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "subject", $$v)
                          },
                          expression: "settings.subject",
                        },
                      }),
                      _vm.settings.subject
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                value: true,
                                "unchecked-value": false,
                                switch: "",
                              },
                              model: {
                                value: _vm.settings.unlock_subject,
                                callback: function ($$v) {
                                  _vm.$set(_vm.settings, "unlock_subject", $$v)
                                },
                                expression: "settings.unlock_subject",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("USER_CAN_UNLOCK")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info-header mt-4" }, [
                    _vm._v(_vm._s(_vm.$t("ENFORCE_EMAIL_BODY"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2 mt-2" },
                    [
                      _c("b-form-textarea", {
                        model: {
                          value: _vm.settings.email_body,
                          callback: function ($$v) {
                            _vm.$set(_vm.settings, "email_body", $$v)
                          },
                          expression: "settings.email_body",
                        },
                      }),
                      _vm.settings.email_body
                        ? _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-2",
                              attrs: {
                                value: true,
                                "unchecked-value": false,
                                switch: "",
                              },
                              model: {
                                value: _vm.settings.unlock_email_body,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.settings,
                                    "unlock_email_body",
                                    $$v
                                  )
                                },
                                expression: "settings.unlock_email_body",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("USER_CAN_UNLOCK")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "info-header mt-4" }, [
                    _vm._v(_vm._s(_vm.$t("MISC"))),
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-2 mt-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("NUITEQ.CLIENT_KEY"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "" },
                            model: {
                              value: _vm.settings.client_key,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "client_key", $$v)
                              },
                              expression: "settings.client_key",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-2",
                          attrs: {
                            "label-cols-md": "2",
                            label: _vm.$t("NUITEQ.HOSTNAME"),
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { trim: "" },
                            model: {
                              value: _vm.settings.hostname,
                              callback: function ($$v) {
                                _vm.$set(_vm.settings, "hostname", $$v)
                              },
                              expression: "settings.hostname",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            value: true,
                            "unchecked-value": false,
                            switch: "",
                          },
                          model: {
                            value: _vm.settings.user_meetings_enabled,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.settings,
                                "user_meetings_enabled",
                                $$v
                              )
                            },
                            expression: "settings.user_meetings_enabled",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("NUITEQ.ALLOW_OWN_MEETINGS")))]
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "p-0 m-0 mt-4 mb-2" }),
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-fill",
                      attrs: { variant: "primary" },
                      on: { click: _vm.updateSettings },
                    },
                    [_vm._v(_vm._s(_vm.$t("SAVE")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }